import React from 'react'
import { useAuth } from './authContext'
import LoggedInApp from './LoggedInApp'
import LoginPage from './pages/LoginPage'

function App() {
    const { isLoggedIn, loginError } = useAuth()

    return isLoggedIn ? <LoggedInApp /> : <LoginPage error={loginError} />
}

export default App
