import React from 'react'
import Feed from '../components/Feed'

const Homepage = () => {
    return (
        <div className="homepage" data-testid="homepage">
            <Feed />
        </div>
    )
}

export default Homepage
