import React, { useState, Fragment } from 'react'
/*
    @atlaskit/quick-search has a deprecated dependency @atlaskit/analytics 
    at the time of 8.0.4 this has still not been updated    

    Console warning:
    The @atlaskit/analytics package has been deprecated. 
    Please use the @atlaskit/analytics-next package instead.

    Functionality is still ok but should be checked regularly when its updated.
*/
import { QuickSearch } from '@atlaskit/quick-search'
import SearchIcon from '@atlaskit/icon/glyph/search'
import Button from '@atlaskit/button'
import { SearchContainer } from '../components/styledComponents'
import Feed from '../components/Feed'

export default function Search() {
    const [searchReady, setSearchReady] = useState(false)
    const [searchQuery, setSearchQuery] = useState()

    const updateQuery = (query) => {
        setSearchReady(false)
        setSearchQuery(query)
    }

    const searchEvents = () => {
        setSearchReady(true)
    }

    return (
        <Fragment>
            <SearchContainer>
                <QuickSearch
                    inputControls={
                        <Button
                            appearance="primary"
                            iconBefore={
                                <SearchIcon label="Search" size="small" />
                            }
                            onClick={() => searchEvents()}
                        >
                            Search
                        </Button>
                    }
                    onSearchInput={({ target }) => updateQuery(target.value)}
                    onSearchSubmit={() => searchEvents()}
                />
            </SearchContainer>

            {searchReady && <Feed searchQuery={searchQuery} />}
        </Fragment>
    )
}
