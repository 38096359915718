import React, { Fragment, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { add, parseISO } from 'date-fns'
import { Field, ErrorMessage } from '@atlaskit/form'
import Toggle from '@atlaskit/toggle'
import TextField from '@atlaskit/textfield'
import { RadioGroup } from '@atlaskit/radio'
import { RECURSION_OPTIONS, NO_RECURSION } from '../consts'
import getRecurranceLabel from '../tools/getRecurranceLabel'
import isEventRecurring from '../tools/isEventRecurring'
import { BACKGROUND_GREY } from '../shared/styles'
import hexToRgba from '../tools/hexToRgba'
import useSettings from '../shared/hooks/useSettings'
import { NarrowContainer, ToggleWrapper } from './styledComponents'

export default function RecurringEvents({
    isRecurring,
    setRecurring,
    eventTypeState,
    recursionPattern = NO_RECURSION,
    recursionCount = 1,
    formState,
}) {
    const { localeFns } = useSettings()
    const { date } = formState()
    const [recurrances, setRecurrances] = useState([])

    const [lastRecurrence] = recurrances.slice(-1)
    const { multiDay, allDay } = eventTypeState
    const toggleRecurring = () => {
        setRecurring((wasRecurring) => !wasRecurring)
    }

    const collapseRecurring = () => {
        const collapsed = {
            maxHeight: '4rem',
            overflow: 'hidden',
            transition: 'all 0.3s ease-in-out',
            backgroundColor: hexToRgba(BACKGROUND_GREY, 0),
            padding: '8px 0',
        }
        const expanded = {
            ...collapsed,
            maxHeight: '900px',
            backgroundColor: hexToRgba(BACKGROUND_GREY, 1),
        }

        return isRecurring ? expanded : collapsed
    }
    const selectedOption = useMemo(() => {
        if (isEventRecurring(recursionPattern)) {
            const defaultLabel = getRecurranceLabel(recursionPattern)

            const [selected] = RECURSION_OPTIONS.filter(
                (option) => option.label === defaultLabel
            )

            return selected.value
        }
        return undefined
    }, [recursionPattern])

    const recurringDates = useCallback(() => {
        const calcRecurranceDates = (parsedDate, duration, recurrences) => {
            const dates = []

            for (let i = 0; i < recurrences; i += 1) {
                const newDate = add(parsedDate, { [duration]: i })

                dates.push(localeFns.formatDate(newDate))
            }
            return dates
        }

        const { recursionCount: stateCount, recursionPattern: statePattern } =
            formState()
        let dateArr
        const parsedDate = parseISO(date)

        switch (statePattern) {
            case 'Daily':
                dateArr = calcRecurranceDates(parsedDate, 'days', stateCount)
                break
            case 'Weekly':
                dateArr = calcRecurranceDates(parsedDate, 'weeks', stateCount)
                break
            case 'Fortnightly':
                dateArr = []
                for (let i = 0; i < stateCount; i += 1) {
                    const newDate = add(parsedDate, { weeks: i * 2 })

                    dateArr.push(localeFns.formatDate(newDate))
                }
                break
            default:
                break
        }
        setRecurrances(dateArr)
    }, [date, formState, localeFns])

    return (
        <section style={collapseRecurring()}>
            <NarrowContainer>
                <ToggleWrapper>
                    Recurring Event
                    <Toggle
                        label="Recurring Event"
                        size="large"
                        name="recurring"
                        isDefaultChecked={isRecurring}
                        onChange={toggleRecurring}
                        isDisabled={multiDay || allDay}
                        testId="recurring"
                    />
                </ToggleWrapper>
                {isRecurring && (
                    <Fragment>
                        <Field
                            name="recursionPattern"
                            defaultValue={selectedOption}
                            label="How often will the event Repeat?"
                            isRequired
                        >
                            {({ fieldProps }) => (
                                <RadioGroup
                                    onBlur={() => recurringDates()}
                                    options={RECURSION_OPTIONS}
                                    {...fieldProps}
                                />
                            )}
                        </Field>
                        <Field
                            name="recursionCount"
                            defaultValue={recursionCount}
                            label="How many recurrences?"
                            isRequired
                        >
                            {({ fieldProps, error }) => (
                                <Fragment>
                                    <TextField
                                        type="number"
                                        {...fieldProps}
                                        min="1"
                                        max="12"
                                        onBlur={() => recurringDates()}
                                    />
                                    {error && (
                                        <ErrorMessage>{error}</ErrorMessage>
                                    )}
                                </Fragment>
                            )}
                        </Field>
                        {recurrances && (
                            <Fragment>
                                <p>
                                    Your event will repeat until{' '}
                                    {lastRecurrence} on the following dates:
                                </p>
                                <ul className="comma-list">
                                    {recurrances.map((recurrance, index) => (
                                        <li key={index}>{recurrance}</li>
                                    ))}
                                </ul>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </NarrowContainer>
        </section>
    )
}

RecurringEvents.schema = {
    recursionPattern: yup
        .string()
        .oneOf(['Daily', 'Weekly', 'Fortnightly'])
        .required(),
    recursionCount: yup
        .number()
        .typeError('Recurrances must be a number')
        .min(1)
        .max(12)
        .required('The number of recurrances is required'),
}

RecurringEvents.propTypes = {
    isRecurring: PropTypes.bool.isRequired,
    setRecurring: PropTypes.func.isRequired,
    eventTypeState: PropTypes.shape({
        multiDay: PropTypes.bool.isRequired,
        allDay: PropTypes.bool.isRequired,
    }),
    recursionCount: PropTypes.number,
    formState: PropTypes.func.isRequired,
    recursionPattern: PropTypes.number,
}
