import axios from 'axios'
import qs from 'qs'
import createService from './shared/hooks/createService'

const extractValue = (response) => {
    return response.data.data
}

const [EventPortalService, useEventPortalService] = createService({
    api: ({ apiBase }) => {
        const apiDefaults = {
            baseURL: `${apiBase}/`,
        }

        axios.defaults.paramsSerializer = (p) =>
            qs.stringify(p, { arrayFormat: 'repeat' })

        const portalApi = axios.create(apiDefaults)
        const authedPortalApi = axios.create(apiDefaults)

        return {
            login(venueId) {
                return portalApi
                    .get('Login', {
                        params: { venueId },
                    })
                    .then((response) => {
                        return response.data.data
                    })
                    .catch((error) => {
                        // Something happened in setting up the request that triggered an Error
                        // eslint-disable-next-line no-console
                        console.log('Error', error.message)

                        return error.response.data
                    })
            },
            reAuth(emailToken) {
                return portalApi({
                    method: 'get',
                    url: 'Reauth',
                    headers: { Authorization: `Bearer ${emailToken}` },
                }).then((response) => {
                    if (response.status === 200) {
                        authedPortalApi.defaults.headers.common.Authorization = `Bearer ${response.data.data.token}`
                    }
                    return response.data.data
                })
            },
            alreadyLoggedIn(cookieToken) {
                authedPortalApi.defaults.headers.common.Authorization = `Bearer ${cookieToken}`
            },
            searchAsManager({
                statuses = undefined,
                term = undefined,
                skip = undefined,
                take = undefined,
                recursion = undefined,
            }) {
                return authedPortalApi
                    .get('SearchAsManager', {
                        params: { statuses, recursion, term, skip, take },
                    })
                    .then(extractValue)
            },
            searchAsApprover({
                statuses = undefined,
                term = undefined,
                skip = undefined,
                take = undefined,
            }) {
                return authedPortalApi
                    .get('SearchAsApprover', {
                        params: { statuses, term, skip, take },
                    })
                    .then(extractValue)
            },
            createEvent({
                category,
                name,
                description,
                internalCategories,
                ticketed,
                startAt,
                endAt,
                allDay,
                recursionPattern,
                recursionCount,
            }) {
                return authedPortalApi
                    .post('Create', {
                        category,
                        name,
                        description,
                        internalCategories,
                        ticketed,
                        startAt,
                        endAt,
                        allDay,
                        recursionPattern,
                        recursionCount,
                    })
                    .then(extractValue)
            },
            getEvent(id) {
                return authedPortalApi
                    .get('Event', {
                        params: { id },
                    })
                    .then(extractValue)
            },
            cancelEvent(id) {
                return authedPortalApi
                    .delete('Event', {
                        params: { id },
                    })
                    .then(extractValue)
            },
            updateEvent({
                id,
                category,
                name,
                description,
                internalCategories,
                ticketed,
                startAt,
                endAt,
                allDay,
                recursionPattern,
                recursionCount,
            }) {
                return authedPortalApi
                    .patch('Event', {
                        id,
                        category,
                        name,
                        description,
                        internalCategories,
                        ticketed,
                        startAt,
                        endAt,
                        allDay,
                        recursionPattern,
                        recursionCount,
                    })
                    .then(extractValue)
            },
            setEventStatus({ id, status, reasons }) {
                const options = {
                    method: 'POST',
                    params: { id, status, reasons },
                    url: 'Workflow',
                }

                return authedPortalApi(options).then(extractValue)
            },
            generateReport({ from, to }) {
                return authedPortalApi
                    .get('GenerateReport', {
                        params: { from, to },
                    })
                    .then((response) => {
                        return response.data
                    })
            },
            getCategories() {
                return authedPortalApi.get('categories').then(extractValue)
            },
        }
    },
    noContextError:
        'useEventPortalService hook must be used within a EventPortalService',
})

export { EventPortalService, useEventPortalService }
