import React from 'react'
import Feed from '../components/Feed'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'

const Approved = () => {
    return (
        <div className="approved">
            <Feed filterStatus={getWorkFlowStatus('Approved')} />
        </div>
    )
}

export default Approved
