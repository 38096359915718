import React, { useState, useCallback, useEffect, useContext } from 'react'
import Cookies from 'js-cookie'
import { useHistory } from 'react-router-dom'
import { useEventPortalService } from './EventPortalService'
import useQuery from './hooks/useQuery'
import {
    APPROVER,
    LOGIN_TOKEN,
    LONG_LEASE_EXPIRY,
    LOGGED_IN_COOKIE,
} from './consts'
import getMinutesFromNow from './tools/getMinutesFromNow'
import LoadingPage from './pages/LoadingPage'
import useErrorState, {
    initialState as initialErrorState,
} from './stores/useErrorState'

const AuthContext = React.createContext()

function AuthProvider({ ...props }) {
    const { reAuth, alreadyLoggedIn } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const query = useQuery()
    const URLToken = query.get(LOGIN_TOKEN)
    const role = query.get('role')
    const history = useHistory()
    const loggedInCookie = Cookies.get(LOGGED_IN_COOKIE)
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [loginError, setLoginError] = useState(false)
    const [userRole, setUserRole] = useState('pubManager')
    const [waitingForAuth, setWaiting] = useState(false)

    const loginTimeout = useCallback(() => {
        setErrorState(initialErrorState)
        if (window.location.href.split('?')[0] === window.location.origin) {
            window.location.href = window.location.origin
        }
        setLoggedIn(false)
        setLoginError(true)
    }, [setErrorState, setLoggedIn, setLoginError])

    const logUserOut = () => {
        setErrorState(initialErrorState)
        if (URLToken) {
            query.delete(LOGIN_TOKEN)
            history.replace({
                search: query.toString(),
            })
        }
        if (loggedInCookie) {
            Cookies.remove(LOGGED_IN_COOKIE)
        }

        setLoggedIn(false)
    }

    const reAuthenticate = useCallback(async () => {
        setWaiting(true)

        try {
            const reauthResponse = await reAuth(URLToken)

            if (reauthResponse) {
                if (reauthResponse.token) {
                    Cookies.set(LOGGED_IN_COOKIE, reauthResponse.token, {
                        expires: getMinutesFromNow(LONG_LEASE_EXPIRY),
                        sameSite: 'strict',
                    })
                }
                if (reauthResponse.name === 'Error') {
                    setLoginError(true)
                }
            }
        } catch (e) {
            loginTimeout()
        } finally {
            setWaiting(false)
        }
    }, [reAuth, URLToken, setWaiting, setLoginError, loginTimeout])

    const initialLogin = useCallback(() => {
        // if the user has already reauthenticated and has a token stored in a long-lease cookie
        if (loggedInCookie) {
            setLoggedIn(true)

            // sets the Token to use as a header in all future requests
            alreadyLoggedIn(loggedInCookie)

            return
        }
        // if they have a short lease URL token reauthenticate to get a long-lease token
        if (URLToken) {
            reAuthenticate()
        }
    }, [loggedInCookie, alreadyLoggedIn, URLToken, reAuthenticate])

    const checkUserRole = useCallback(() => {
        // if role=approver is in the url, set the token to be the axios default
        if (APPROVER === role) {
            setUserRole(APPROVER)
            alreadyLoggedIn(URLToken)
            setLoggedIn(true)
        }
    }, [role, alreadyLoggedIn, URLToken])

    useEffect(() => {
        if (role) {
            checkUserRole()
            return
        }
        if (!isLoggedIn) {
            initialLogin()
        }
    }, [isLoggedIn, initialLogin, role, checkUserRole, userRole])

    if (waitingForAuth) {
        return <LoadingPage />
    }

    return (
        <AuthContext.Provider
            value={{
                isLoggedIn,
                loginError,
                userRole,
                loginTimeout,
                logUserOut,
            }}
            {...props}
        />
    )
}
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
