import React from 'react'
import PropTypes from 'prop-types'
import Button from '@atlaskit/button'
import { NavLink } from 'react-router-dom'

export default function ButtonLink({ href, appearance, label, icon }) {
    return (
        <div>
            <Button
                href={href}
                appearance={appearance || 'default'}
                iconBefore={icon}
                // eslint-disable-next-line react/display-name
                component={React.forwardRef(
                    (
                        // eslint-disable-next-line react/prop-types, no-shadow
                        { href = '', children, ...rest },
                        ref
                    ) => (
                        <NavLink exact {...rest} to={href} innerRef={ref}>
                            {children}
                        </NavLink>
                    )
                )}
            >
                {label}
            </Button>
        </div>
    )
}

ButtonLink.propTypes = {
    href: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    appearance: PropTypes.string,
    icon: PropTypes.element,
}
