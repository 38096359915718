import React from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter as Router } from 'react-router-dom'
import { createLocalizationProvider } from '@atlaskit/locale'
import { EventPortalService } from './EventPortalService'
import { AuthProvider } from './authContext'
import SettingsContext from './shared/context/settings'

export default function Providers({ children, config }) {
    const apiBase = config.api_url
    const locale = 'en-GB'
    const localeFns = createLocalizationProvider(locale)
    const appConfig = { ...config, locale, localeFns }

    return (
        <Router>
            <SettingsContext.Provider value={appConfig}>
                <EventPortalService apiBase={apiBase}>
                    <AuthProvider>{children}</AuthProvider>
                </EventPortalService>
            </SettingsContext.Provider>
        </Router>
    )
}

Providers.propTypes = {
    children: PropTypes.node.isRequired,
    config: PropTypes.object,
}
