/*
Converts Hex colours to rgba
Usage:
  hexToRgbA('#fbafff', 0.8)
returned value: (String)
  rgba(251,175,255,0.8)
*/
export default function hexToRgbA(hex, alpha) {
    let c

    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('')
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]]
        }
        c = `0x${c.join('')}`
        // eslint-disable-next-line no-bitwise
        return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(
            ','
        )},${alpha})`
    }
    throw new Error('Bad Hex')
}
