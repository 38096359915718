import React from 'react'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import useErrorState, {
    initialState as initialErrorState,
} from '../stores/useErrorState'

export default function ErrorModal() {
    const [errorState, setErrorState] = useErrorState()
    const { errorMessage, errorActions, errorTitle, errorAppearance } =
        errorState
    const close = () => {
        setErrorState(initialErrorState)
    }

    const defaultAction = [
        { text: 'Close', onClick: close, appearance: 'subtle' },
    ]
    const actions = () => {
        if (errorActions) {
            return [...defaultAction, ...errorActions]
        }
        return defaultAction
    }

    return (
        <ModalTransition>
            <Modal
                actions={actions()}
                onClose={close}
                heading={errorTitle}
                appearance={errorAppearance}
            >
                {errorMessage}
            </Modal>
        </ModalTransition>
    )
}
