// TODO: This file is copy/pasted from GKWF and should be imported from a shared repository
import React, { createContext, useContext, useMemo } from 'react'
import PropTypes from 'prop-types'

export default function createService({
    api,
    noContextError = 'useService hook must be used within a ServiceProvider',
}) {
    const ServiceContext = createContext()

    function ServiceProvider({ children, ...props }) {
        const value = useMemo(() => api(props), [props])

        return (
            <ServiceContext.Provider value={value}>
                {children}
            </ServiceContext.Provider>
        )
    }

    ServiceProvider.propTypes = {
        children: PropTypes.node,
    }

    function useService() {
        const context = useContext(ServiceContext)

        if (!context) {
            throw new Error(noContextError)
        }

        return context
    }

    return [ServiceProvider, useService]
}
