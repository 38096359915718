import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { parseISO } from 'date-fns'
import { Grid, GridColumn } from '@atlaskit/page'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import InlineMessage from '@atlaskit/inline-message'
import ButtonLink from './ButtonLink'
import EventTypeBadges from './EventTypeBadges'
import StatusLozenge from './StatusLozenge'
import useErrorState from '../stores/useErrorState'
import { useAuth } from '../authContext'
import { useEventPortalService } from '../EventPortalService'
import useEventState from '../stores/useEventState'
import getRecurranceLabel from '../tools/getRecurranceLabel'
import { setError, handleError } from '../tools/errorHandlers'
import isEventMultiday from '../tools/isEventMultiday'
import isEventRecurring from '../tools/isEventRecurring'
import { INTERACTION_DELAY } from '../consts'

import {
    TextRight,
    CardRow,
    FooterCtrls,
    FullWidthCard,
} from './styledComponents'
import useSettings from '../shared/hooks/useSettings'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'

export default function ApproverCard({
    id,
    name,
    description,
    venueId,
    category,
    ticketed,
    startAt,
    endAt,
    allDay,
    recursionPattern,
    recursionCount,
    workflow,
    triggerRejection,
    removeEvent,
}) {
    const { loginTimeout } = useAuth()
    const { setEventStatus } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const [eventState] = useEventState()

    const startDateTime = parseISO(startAt)
    const endDateTime = parseISO(endAt)
    const { localeFns } = useSettings()
    const { formatDate, formatTime } = localeFns
    const [ctrlLoading, setCtrlLoading] = useState(false)
    const [approvalSuccess, setApprovalSuccess] = useState(false)

    const { status, changedAtUtc } = workflow[0]

    const changedAt = parseISO(changedAtUtc)
    const { venues } = eventState

    const multiDay = useMemo(
        () => isEventMultiday(startDateTime, endDateTime),
        [startDateTime, endDateTime]
    )
    const recurring = useMemo(
        () => isEventRecurring(recursionPattern),
        [recursionPattern]
    )
    const edited = getWorkFlowStatus('Edited')

    const venueInfo = useMemo(() => {
        const eventVenue = venues.find((venue) => {
            return venue.id === venueId
        })

        return eventVenue
    }, [venues, venueId])

    const eventTypes = {
        ticketed,
        allDay,
        multiDay,
        recurring,
        recursionCount,
    }

    const removeOnSuccess = useCallback(
        () => setTimeout(() => removeEvent(id), INTERACTION_DELAY),
        [id, removeEvent]
    )

    const triggerApproval = async () => {
        const options = {
            id,
            status: 'Approved',
        }

        setCtrlLoading(true)
        try {
            const approved = await setEventStatus(options)

            if (approved) {
                setApprovalSuccess(true)
                removeOnSuccess()
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error.message, setErrorState()),
                401: () => loginTimeout(),
            })
        }

        setCtrlLoading(false)
    }

    return (
        <FullWidthCard>
            <CardRow>
                <Grid>
                    <GridColumn>
                        <TextRight>
                            <StatusLozenge status={status} />
                        </TextRight>
                    </GridColumn>
                </Grid>
            </CardRow>
            <CardRow>
                <Grid>
                    <GridColumn medium={6}>
                        <h3 style={{ display: 'flex' }}>{name}</h3>
                    </GridColumn>
                    <GridColumn>
                        <h4>
                            <em>{`${venueInfo.address.name}, ${venueInfo.address.county}`}</em>
                        </h4>
                    </GridColumn>
                    <GridColumn>
                        <TextRight>
                            <EventTypeBadges {...eventTypes} />
                        </TextRight>
                    </GridColumn>
                </Grid>
            </CardRow>

            <hr />
            <CardRow>
                <Grid spacing="compact">
                    <GridColumn medium={6}>
                        <Grid>
                            <GridColumn>
                                <strong>Category:</strong>
                            </GridColumn>
                            <GridColumn>
                                <TextRight>{category}</TextRight>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                    {!multiDay ? (
                        <GridColumn medium={6}>
                            <Grid>
                                <GridColumn>
                                    <strong>Date:</strong>
                                </GridColumn>

                                <GridColumn>
                                    <TextRight>
                                        {formatDate(startDateTime)}
                                    </TextRight>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                    ) : (
                        <GridColumn medium={6}>
                            <Grid spacing="compact">
                                <GridColumn>
                                    <Grid spacing="compact">
                                        <GridColumn>
                                            <strong>Start Date:</strong>
                                        </GridColumn>
                                        <GridColumn>
                                            <TextRight>
                                                {formatDate(startDateTime)}
                                            </TextRight>
                                        </GridColumn>
                                    </Grid>
                                </GridColumn>
                                <GridColumn>
                                    <Grid>
                                        <GridColumn>
                                            <strong>End Date:</strong>
                                        </GridColumn>
                                        <GridColumn>
                                            <TextRight>
                                                {formatDate(endDateTime)}
                                            </TextRight>
                                        </GridColumn>
                                    </Grid>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                    )}
                </Grid>
            </CardRow>
            {!allDay && (
                <CardRow>
                    <Grid spacing="compact">
                        <GridColumn medium={6}>
                            <Grid spacing="compact">
                                <GridColumn>
                                    <strong>Start Time:</strong>
                                </GridColumn>
                                <GridColumn>
                                    <TextRight>
                                        {formatTime(startDateTime)}
                                    </TextRight>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                        <GridColumn medium={6}>
                            <Grid spacing="compact">
                                <GridColumn>
                                    <strong>End Time:</strong>
                                </GridColumn>
                                <GridColumn>
                                    <TextRight>
                                        {formatTime(endDateTime)}
                                    </TextRight>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                    </Grid>
                </CardRow>
            )}

            {recurring && (
                <CardRow>
                    <Grid>
                        <GridColumn medium={6}>
                            <Grid>
                                <GridColumn>
                                    <strong>Recurrs:</strong>
                                </GridColumn>
                                <GridColumn>
                                    <TextRight>
                                        {getRecurranceLabel(recursionPattern)}
                                    </TextRight>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                        <GridColumn medium={6}>
                            <Grid>
                                <GridColumn>
                                    <strong>Recurrence count:</strong>
                                </GridColumn>
                                <GridColumn>
                                    <TextRight>{recursionCount}</TextRight>
                                </GridColumn>
                            </Grid>
                        </GridColumn>
                    </Grid>
                </CardRow>
            )}
            <CardRow>
                <Grid>
                    <GridColumn medium={6}>
                        <Grid>
                            <GridColumn medium={4}>
                                <strong>Brand:</strong>
                            </GridColumn>
                            <GridColumn>
                                <TextRight>
                                    <p>{venueInfo.brand.name}</p>
                                </TextRight>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                    <GridColumn medium={6}>
                        <Grid>
                            <GridColumn medium={4}>
                                <strong>Brand Segment:</strong>
                            </GridColumn>
                            <GridColumn>
                                <TextRight>
                                    <p>{venueInfo.brand.segmentName}</p>
                                </TextRight>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                </Grid>
            </CardRow>
            <CardRow>
                <Grid>
                    <GridColumn medium={6}>
                        <Grid>
                            <GridColumn medium={4}>
                                <strong>
                                    {
                                        /* eslint-disable operator-linebreak */
                                        status === edited
                                            ? `Last Edited:`
                                            : `Created at:`
                                        /* eslint-enable operator-linebreak */
                                    }
                                </strong>
                            </GridColumn>
                            <GridColumn>
                                <TextRight>
                                    <p>{`${localeFns.formatTime(
                                        changedAt
                                    )} - ${localeFns.formatDate(
                                        changedAt
                                    )}`}</p>
                                </TextRight>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                    <GridColumn medium={6}>
                        <Grid>
                            <GridColumn medium={4}>
                                <strong>House Number:</strong>
                            </GridColumn>
                            <GridColumn>
                                <TextRight>
                                    <p>{venueId}</p>
                                </TextRight>
                            </GridColumn>
                        </Grid>
                    </GridColumn>
                </Grid>
            </CardRow>

            <CardRow>
                <Grid spacing="compact" layout="fluid">
                    <GridColumn medium={4}>
                        <strong>Description:</strong>
                    </GridColumn>
                    <GridColumn>
                        <p>{description}</p>
                    </GridColumn>
                </Grid>
            </CardRow>
            <hr />
            <FooterCtrls>
                <ButtonGroup>
                    {approvalSuccess && (
                        <InlineMessage
                            type="confirmation"
                            title="Event Approved"
                        />
                    )}
                    <ButtonLink href={`/editevent?id=${id}`} label="Edit" />
                    <Button
                        appearance="danger"
                        onClick={() => triggerRejection(id)}
                    >
                        Reject
                    </Button>
                    <LoadingButton
                        appearance="primary"
                        onClick={() => triggerApproval(id)}
                        isLoading={ctrlLoading}
                    >
                        Approve
                    </LoadingButton>
                </ButtonGroup>
            </FooterCtrls>
        </FullWidthCard>
    )
}

ApproverCard.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    ticketed: PropTypes.bool,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    venueId: PropTypes.string,
    allDay: PropTypes.bool,
    workflow: PropTypes.array,
    recursionPattern: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    recursionCount: PropTypes.number,
    triggerRejection: PropTypes.func,
    triggerDeletion: PropTypes.func,
    removeEvent: PropTypes.func,
}
