import React from 'react'
import useQuery from '../hooks/useQuery'
import EditEventForm from '../components/EditEventForm'

const EditEvent = () => {
    const query = useQuery()
    const id = query.get('id')

    return (
        <div className="edit-event">
            <EditEventForm id={id} />
        </div>
    )
}

export default EditEvent
