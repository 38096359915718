import React from 'react'
import PropTypes from 'prop-types'
import Spinner from '@atlaskit/spinner'
import { CentredSpinner } from './styledComponents'

const Loader = ({ size = 'large' }) => (
    <CentredSpinner data-testid="loader">
        <Spinner size={size} />
    </CentredSpinner>
)

Loader.propTypes = {
    size: PropTypes.string,
}
export default Loader
