import React, { Fragment } from 'react'
import SectionMessage from '@atlaskit/section-message'
import ButtonLink from '../components/ButtonLink'
import { NarrowContainer, Padding } from '../components/styledComponents'
import { useAuth } from '../authContext'
import { APPROVER } from '../consts'

const Pending = () => {
    const { userRole } = useAuth()

    return (
        <div className="success">
            <NarrowContainer>
                <SectionMessage appearance="confirmation">
                    {userRole === APPROVER ? (
                        <Fragment>
                            <p>This event has been successfully updated.</p>
                            <p>
                                <strong>
                                    Please note: This event still requires
                                    approval before it is live on the pub
                                    website.
                                </strong>
                            </p>
                            <p>
                                Please head back to the list view to find and
                                approve the updated event.
                            </p>
                            <p>Once approved the event will go live.</p>
                        </Fragment>
                    ) : (
                        <p>
                            Your event has been submitted for approval by your
                            Brand Manager.
                        </p>
                    )}
                </SectionMessage>
                <Padding>
                    {userRole === APPROVER ? (
                        <ButtonLink
                            appearance="primary"
                            href="/"
                            label="View All Events"
                        />
                    ) : (
                        <ButtonLink
                            appearance="primary"
                            href="/addevent"
                            label="Add Another Event"
                        />
                    )}
                </Padding>
            </NarrowContainer>
        </div>
    )
}

export default Pending
