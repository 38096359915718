import { parseISO, formatISO } from 'date-fns'
import combineDateTime from './combineDateTime'
import { NO_RECURSION } from '../consts'

const calcDateTimes = (formData, allDay, multiDay) => {
    const { endTime, startTime } = formData

    if (allDay) {
        const DateTime = formatISO(parseISO(formData.date))

        return {
            startAt: DateTime,
            endAt: DateTime,
        }
    }

    if (!multiDay && !allDay) {
        // a regular single day event
        const { date } = formData
        const startAt = combineDateTime(date, startTime)
        const endAt = combineDateTime(date, endTime)

        return {
            startAt,
            endAt,
        }
    }
    // else a multiday event
    const startAt = combineDateTime(formData.startDate, startTime)
    const endAt = combineDateTime(formData.endDate, endTime)

    return {
        startAt,
        endAt,
    }
}

export default function encodeEvent(
    formData,
    allDay,
    multiDay,
    isRecurring,
    selectedCategories
) {
    const { name, description } = formData
    const category = formData.category.value
    const ticketed = formData.ticketed.length > 0

    const internalCategories = (() => {
        const cats = [...selectedCategories]

        if (formData.internalCategory) {
            cats.push(formData.internalCategory)
        }
        return cats
    })()

    const recursionPattern = () =>
        isRecurring ? formData.recursionPattern : NO_RECURSION
    const recursionCount = () => (isRecurring ? formData.recursionCount : 0)

    const dateStamps = calcDateTimes(formData, allDay, multiDay)
    const encodedEvent = {
        category,
        internalCategories,
        name,
        description,
        ticketed,
        ...dateStamps,
        allDay,
        recursionPattern: recursionPattern(),
        recursionCount: recursionCount(),
    }

    return encodedEvent
}
