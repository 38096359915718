import { parseISO, formatISO, set, getHours, getMinutes, parse } from 'date-fns'

export default function combineDateTime(date, time) {
    const parseTime = parse(time, 'HH:mm', new Date())
    const parseDate = parseISO(date)
    const dateTime = set(parseDate, {
        hours: getHours(parseTime),
        minutes: getMinutes(parseTime),
    })

    return formatISO(dateTime)
}
