import React, { useState, Fragment } from 'react'
import PropTypes from 'prop-types'
import Page from '@atlaskit/page'
import Modal, { ModalTransition } from '@atlaskit/modal-dialog'
import LoginForm from '../components/LoginForm'
import { CentredCard, FullScreen } from '../components/styledComponents'

const LoginPage = ({ error }) => {
    const [loginError, setLoginError] = useState(error)

    const close = () => {
        setLoginError(false)
    }

    return (
        <Fragment>
            {loginError && (
                <ModalTransition>
                    <Modal
                        actions={[{ text: 'Close', onClick: close }]}
                        onClose={close}
                        heading="Sorry"
                        appearance="warning"
                        testId="login-error-modal"
                    >
                        There was a problem logging you in, please login again.
                    </Modal>
                </ModalTransition>
            )}
            <Page>
                <FullScreen>
                    <CentredCard data-testid="login">
                        <h1>Events Portal</h1>
                        <h2>Login</h2>
                        <p>
                            Please enter Your House Number and the login link
                            will be delivered by email.
                        </p>
                        <LoginForm />
                    </CentredCard>
                </FullScreen>
            </Page>
        </Fragment>
    )
}

LoginPage.propTypes = {
    error: PropTypes.bool.isRequired,
}

export default LoginPage
