import styled from 'styled-components'
import { BACKGROUND_GREY, BOX_SHADOW, INFO_BLUE } from '../shared/styles'

export const NarrowContainer = styled.div`
    display: flex;
    width: 650px;
    max-width: 100%;
    margin: 8px auto;
    flex-direction: column;
`
export const SearchContainer = styled.div`
    display: flex;
    width: 650px;
    max-width: 100%;
    margin: 0 5rem 2rem auto;
    flex-direction: column;
`
export const ToggleWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: flex-end;
    align-items: center;
`
export const SubTitle = styled.div`
    text-align: center;
    margin: 3rem 0 2rem;
`

export const Padding = styled.div`
    padding: 1rem 0 0;
`
export const PaddingBottom = styled.div`
    padding: 0 0 1rem;
`
export const InlinePadding = styled.div`
    padding: 0.25rem 0.15rem 0.5rem;
`
export const FullScreen = styled.div`
    display: flex;
    width: 100%;
    height: 100vh;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    background-color: ${BACKGROUND_GREY};
`

export const FullWidthCard = styled.div`
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    margin-bottom: 1rem;
    box-shadow: ${BOX_SHADOW};
`
export const CentredCard = styled.div`
    max-width: 450px;
    width: 400px;
    background-color: #fff;
    padding: 2rem;
    border-radius: 1rem;
    box-shadow: ${BOX_SHADOW};
`
export const CentredContainer = styled.div`
    max-width: 450px;
    width: 400px;
    padding: 1rem 2rem;
    margin: 0 auto;
`
export const CentredSpinner = styled.div`
    padding: 1rem 0;
    display: flex;
    justify-content: space-around;
`

export const CheckboxGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(165px, 1fr));
`

export const TextRight = styled.div`
    text-align: right;
`
export const CardRow = styled.div`
    padding: 0 0 0.375rem;
`
export const Legend = styled.legend`
    font-size: 0.8571428571428571em;
    font-style: inherit;
    line-height: 1.3333333333333333;
    color: #6b778c;
    font-weight: 600;
    margin-top: 16px;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    margin-bottom: 4px;
    margin-top: 0;
`
export const EventTypeBadge = styled.div`
    display: inline-flex;
    align-items: center;
    background-color: ${INFO_BLUE};
    border-radius: 0.5rem;
    padding: 0.25rem 0.5rem;
    margin: 0 0.25rem;
`
export const TypeLabel = styled.div`
    padding-left: 0.5rem;
`

export const FooterCtrls = styled.div`
    text-align: right;
    padding: 0.5rem 0 0;
`
export const Centred = styled.div`
    max-width: auto;
    margin: auto;
    padding: 1rem 0;
`

export const SortByWrapper = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
`

export const ReasonsWrapper = styled.div`
    padding: 1rem 0;
    background-color: ${BACKGROUND_GREY};
    margin-bottom: 1rem;
`
export const ErrorPadding = styled.div`
    padding: 3rem 0 0;
`
export const MarginRightAuto = styled.div`
    margin-right: auto;
`
