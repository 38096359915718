import React from 'react'

export default function FormIntroText() {
    return (
        <div>
            <ol>
                <li>
                    Make sure you check your spelling and grammar on your Event
                    Name and Description, as this is what guests will see on
                    your website. If you are unsure you can copy and paste your
                    content into{' '}
                    <a
                        href="https://sentencechecker.com"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        https://sentencechecker.com
                    </a>{' '}
                    and it will check it for you highlighting any errors
                </li>
                <li>
                    Double-check that your dates and times you have entered are
                    correct to avoid guest disappointment.
                </li>
            </ol>
        </div>
    )
}
