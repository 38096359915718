// Format time Helper - if it's less than 10 ie. 8 => 08
const prependZero = (i) => (i < 10 ? `0${i}` : i)

/* Accepts two 24hr times in String format "10:00" - "13:00"
and returns an array of intervals between them in minutes */
export default function createTimeIntervals(from, until, interval) {
    // We need an arbitary date for Date.parse to work with the times
    const arbitaryDay = '01/01/2001'
    const intervalsPerHour = 60 / interval
    const milisecsPerHour = 60 * 60 * 1000

    const calcUntil = Date.parse(`${arbitaryDay} ${until}`)
    const calcFrom = Date.parse(`${arbitaryDay} ${from}`)

    const max =
        (Math.abs(calcUntil - calcFrom) / milisecsPerHour) * intervalsPerHour

    const time = new Date(calcFrom)
    const intervals = []

    for (let i = 0; i <= max; i += 1) {
        const hour = prependZero(time.getHours())
        const minute = prependZero(time.getMinutes())

        intervals.push(`${hour}:${minute}`)
        time.setMinutes(time.getMinutes() + interval)
    }
    return intervals
}
