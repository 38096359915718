import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import ModalDialog, {
    ModalFooter,
    ModalTransition,
} from '@atlaskit/modal-dialog'
import InlineMessage from '@atlaskit/inline-message'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import Form, { Field, CheckboxField } from '@atlaskit/form'
import { Checkbox } from '@atlaskit/checkbox'
import TextArea from '@atlaskit/textarea'
import { REJECTION_REASONS, INTERACTION_DELAY } from '../consts'
import { useEventPortalService } from '../EventPortalService'
import useErrorState from '../stores/useErrorState'
import { useAuth } from '../authContext'
import getReasonLabel from '../tools/getReasonLabel'
import { setError, handleError } from '../tools/errorHandlers'

export default function RejectEvent({
    rejectState,
    setRejectState,
    removeEvent,
}) {
    const [isLoading, setIsLoading] = useState(false)
    const [rejectionSuccess, setRejectionSuccess] = useState(false)
    const { setEventStatus } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const { loginTimeout } = useAuth()
    const { rejectOpen, idToReject } = rejectState

    const close = useCallback(() => {
        setRejectState((prevEventTypeState) => ({
            ...prevEventTypeState,
            rejectOpen: false,
        }))
        setRejectionSuccess(false)
    }, [setRejectState, setRejectionSuccess])

    const closeOnSuccess = useCallback(
        () => setTimeout(() => close(), INTERACTION_DELAY),
        [close]
    )

    const onFormSubmit = async (data) => {
        const { rejectionReason, otherReason } = data
        const reason = () => {
            const reasons = []

            rejectionReason.forEach((item) => {
                reasons.push(getReasonLabel(item))
            })

            if (otherReason) {
                reasons.push(otherReason)
            }

            return reasons
        }
        const options = {
            id: idToReject,
            status: 'Rejected',
            reasons: reason(),
        }

        setIsLoading(true)
        try {
            const rejectionResponse = await setEventStatus(options)

            if (rejectionResponse) {
                setRejectionSuccess(true)
                removeEvent(idToReject)
                closeOnSuccess()
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error, setErrorState),
                401: () => loginTimeout(),
            })
        }
        setIsLoading(false)
    }

    const footer = () => (
        <ModalFooter showKeyline={true}>
            <span />
            {rejectionSuccess && (
                <InlineMessage type="confirmation" title="Event Rejected" />
            )}
            <ButtonGroup>
                <Button appearance="default" onClick={close}>
                    Close
                </Button>
                <LoadingButton
                    appearance="danger"
                    type="submit"
                    isLoading={isLoading}
                >
                    Reject
                </LoadingButton>
            </ButtonGroup>
        </ModalFooter>
    )

    return (
        <ModalTransition>
            {rejectOpen && (
                <ModalDialog
                    heading="Please Select a Reason"
                    onClose={close}
                    components={{
                        // eslint-disable-next-line react/prop-types, react/display-name
                        Container: ({ children, className }) => (
                            <Form onSubmit={onFormSubmit}>
                                {({ formProps }) => (
                                    <form {...formProps} className={className}>
                                        {children}
                                    </form>
                                )}
                            </Form>
                        ),
                        Footer: footer,
                    }}
                >
                    <div style={{ marginBottom: '1rem' }}>
                        {REJECTION_REASONS.map((reason) => (
                            <CheckboxField
                                name={reason.name}
                                value={reason.value}
                                key={reason.value}
                            >
                                {({ fieldProps }) => (
                                    <Checkbox
                                        {...fieldProps}
                                        label={reason.label}
                                    />
                                )}
                            </CheckboxField>
                        ))}

                        <Field name="otherReason" defaultValue="" label="Other">
                            {({ fieldProps }) => (
                                <TextArea {...fieldProps} rows="4" />
                            )}
                        </Field>
                    </div>
                </ModalDialog>
            )}
        </ModalTransition>
    )
}

RejectEvent.propTypes = {
    rejectState: PropTypes.shape({
        rejectOpen: PropTypes.bool,
        idToReject: PropTypes.string,
    }),
    setRejectState: PropTypes.func.isRequired,
    removeEvent: PropTypes.func.isRequired,
}
