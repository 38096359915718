import React, { useState } from 'react'
import Form, { FormFooter } from '@atlaskit/form'
import Button, { ButtonGroup, LoadingButton } from '@atlaskit/button'
import DownloadIcon from '@atlaskit/icon/glyph/download'
import { useEventPortalService } from '../EventPortalService'
import { setError, handleError } from '../tools/errorHandlers'
import { useAuth } from '../authContext'
import useErrorState from '../stores/useErrorState'
import MultiDayDatePickers from './MultiDayDatePickers'

const ReportingForm = () => {
    const [isLoading, setLoading] = useState(false)
    const [, setErrorState] = useErrorState()
    const { loginTimeout } = useAuth()
    const [report, setReport] = useState({
        hasReport: false,
        startDate: undefined,
        endDate: undefined,
        reportData: undefined,
    })
    const { generateReport } = useEventPortalService()

    const getReport = async ({ startDate, endDate }) => {
        setLoading(true)

        try {
            const reportResult = await generateReport({
                from: startDate,
                to: endDate,
            })

            if (reportResult) {
                setReport({
                    hasReport: true,
                    startDate,
                    endDate,
                    reportData: escape(reportResult),
                })
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error, setErrorState),
                401: () => loginTimeout(),
            })
        }
        setLoading(false)
    }

    return (
        <div>
            <Form onSubmit={(data) => getReport(data)}>
                {({ formProps }) => (
                    <form {...formProps}>
                        <MultiDayDatePickers />
                        <FormFooter>
                            <ButtonGroup>
                                {' '}
                                <LoadingButton
                                    name="submit"
                                    type="submit"
                                    appearance="primary"
                                    isLoading={isLoading}
                                >
                                    Generate Report
                                </LoadingButton>
                                {report.hasReport && (
                                    <Button
                                        iconBefore={
                                            <DownloadIcon size="small" />
                                        }
                                    >
                                        <a
                                            href={`data:text/csv;charset=utf-8,${report.reportData}`}
                                            download={`events-report_${report.startDate}_${report.endDate}.csv`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Download Report
                                        </a>
                                    </Button>
                                )}
                            </ButtonGroup>
                        </FormFooter>
                    </form>
                )}
            </Form>
        </div>
    )
}

export default ReportingForm
