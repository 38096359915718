import React from 'react'
import Page from '@atlaskit/page'
import Spinner from '@atlaskit/spinner'
import { FullScreen } from '../components/styledComponents'

export default function LoadingPage() {
    return (
        <Page>
            <FullScreen>
                <Spinner size="xlarge" />
            </FullScreen>
        </Page>
    )
}
