const sortBy = (data, valueToSort, direction = 'ASC') => {
    if (direction === 'ASC') {
        return data.sort((a, b) => {
            return new Date(a[valueToSort]) - new Date(b[valueToSort])
        })
    }
    return data.sort((a, b) => {
        return new Date(a[valueToSort]) < new Date(b[valueToSort])
    })
}

export default sortBy
