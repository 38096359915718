import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { parseISO } from 'date-fns'
import { Grid, GridColumn } from '@atlaskit/page'
import Button, { ButtonGroup } from '@atlaskit/button'
import ButtonLink from './ButtonLink'
import EventTypeBadges from './EventTypeBadges'
import StatusLozenge from './StatusLozenge'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'
import getRecurranceLabel from '../tools/getRecurranceLabel'
import isEventMultiday from '../tools/isEventMultiday'
import isEventRecurring from '../tools/isEventRecurring'

import {
    TextRight,
    CardRow,
    CentredCard,
    FooterCtrls,
    ReasonsWrapper,
    CentredContainer,
} from './styledComponents'
import useSettings from '../shared/hooks/useSettings'

export default function Event({
    id,
    name,
    description,
    category,
    ticketed,
    startAt,
    endAt,
    allDay,
    recursionPattern,
    recursionCount,
    workflow,
    triggerDeletion,
}) {
    const startDateTime = parseISO(startAt)
    const endDateTime = parseISO(endAt)
    const { localeFns } = useSettings()
    const { formatDate, formatTime } = localeFns

    const { status } = workflow[0]

    const multiDay = useMemo(
        () => isEventMultiday(startDateTime, endDateTime),
        [startDateTime, endDateTime]
    )
    const recurring = useMemo(
        () => isEventRecurring(recursionPattern),
        [recursionPattern]
    )

    const rejected = useMemo(
        () => status === getWorkFlowStatus('Rejected'),
        [status]
    )

    const eventTypes = {
        ticketed,
        allDay,
        multiDay,
        recurring,
        recursionCount,
    }

    const rejectionReasons = (children) => {
        return (
            <ReasonsWrapper>
                {children}
                <CentredContainer>
                    <h4>Rejection Reasons</h4>
                    <ul>
                        {workflow[0].changeMetaData.map((reason, index) => (
                            <li key={index}>{reason}</li>
                        ))}
                    </ul>
                </CentredContainer>
            </ReasonsWrapper>
        )
    }
    const Rejection = ({ children }) => {
        return rejected ? rejectionReasons(children) : children
    }

    return (
        <Rejection>
            <CentredCard style={{ margin: '0 auto 1rem' }}>
                <CardRow>
                    <Grid layout="fluid">
                        <GridColumn>
                            <TextRight>
                                <StatusLozenge status={status} />
                            </TextRight>
                        </GridColumn>
                    </Grid>
                </CardRow>

                <CardRow>
                    <Grid>
                        <GridColumn>
                            <h3>{name}</h3>
                        </GridColumn>
                    </Grid>
                </CardRow>
                <CardRow>
                    <Grid>
                        <GridColumn>
                            <TextRight>
                                <EventTypeBadges {...eventTypes} />
                            </TextRight>
                        </GridColumn>
                    </Grid>
                </CardRow>
                <hr />

                <CardRow>
                    <Grid layout="fluid">
                        <GridColumn>
                            <strong>Category:</strong>
                        </GridColumn>
                        <GridColumn>
                            <TextRight>{category}</TextRight>
                        </GridColumn>
                    </Grid>
                </CardRow>
                {!multiDay ? (
                    <CardRow>
                        <Grid layout="fluid">
                            <GridColumn>
                                <strong>Date:</strong>
                            </GridColumn>

                            <GridColumn>
                                <TextRight>
                                    {formatDate(startDateTime)}
                                </TextRight>
                            </GridColumn>
                        </Grid>
                    </CardRow>
                ) : (
                    <CardRow>
                        <Grid>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>Start Date:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>
                                            {formatDate(startDateTime)}
                                        </TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>End Date:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>
                                            {formatDate(endDateTime)}
                                        </TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                        </Grid>
                    </CardRow>
                )}
                {!allDay && (
                    <CardRow>
                        <Grid>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>Start Time:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>
                                            {formatTime(startDateTime)}
                                        </TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>End Time:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>
                                            {formatTime(endDateTime)}
                                        </TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                        </Grid>
                    </CardRow>
                )}

                {recurring && (
                    <CardRow>
                        <Grid>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>Recurrs:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>
                                            {getRecurranceLabel(
                                                recursionPattern
                                            )}
                                        </TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                            <GridColumn medium={6}>
                                <Grid layout="fluid">
                                    <GridColumn>
                                        <strong>Recurrence count:</strong>
                                    </GridColumn>
                                    <GridColumn>
                                        <TextRight>{recursionCount}</TextRight>
                                    </GridColumn>
                                </Grid>
                            </GridColumn>
                        </Grid>
                    </CardRow>
                )}

                <CardRow>
                    <Grid spacing="compact" layout="fluid">
                        <GridColumn medium={4}>
                            <strong>Description:</strong>
                        </GridColumn>
                        <GridColumn>
                            <p>{description}</p>
                        </GridColumn>
                    </Grid>
                </CardRow>
                <hr />
                <FooterCtrls>
                    <ButtonGroup>
                        <ButtonLink href={`/editevent?id=${id}`} label="Edit" />

                        <Button
                            appearance="danger"
                            onClick={() => triggerDeletion(id)}
                        >
                            Cancel
                        </Button>
                    </ButtonGroup>
                </FooterCtrls>
            </CentredCard>
        </Rejection>
    )
}
Event.propTypes = {
    id: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    ticketed: PropTypes.bool,
    startAt: PropTypes.string,
    endAt: PropTypes.string,
    allDay: PropTypes.bool,
    workflow: PropTypes.array,
    recursionPattern: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    recursionCount: PropTypes.number,
    triggerRejection: PropTypes.func,
    triggerDeletion: PropTypes.func,
    removeEvent: PropTypes.func,
}
