import { parseISO, differenceInDays, formatISO, format } from 'date-fns'

export default function decodeEvent({
    name,
    description,
    internalCategories,
    category,
    ticketed,
    startAt,
    endAt,
    allDay,
    recurrencePattern,
    recurrenceCount,
}) {
    const startDateTime = parseISO(startAt)
    const endDateTime = parseISO(endAt)

    const formatDate = (dateTime) =>
        formatISO(dateTime, { representation: 'date' })
    const formatTime = (dateTime) => format(dateTime, 'HH:mm')

    const isMultiday = (startDT, endDT) => {
        return differenceInDays(endDT, startDT) > 0
    }
    const isRecurring = (pattern) => {
        return pattern !== 0
    }
    const recurring = isRecurring(recurrencePattern)
    const multiDay = isMultiday(startDateTime, endDateTime)

    let decodedEvent = {
        name,
        description,
        internalCategories,
        category,
        ticketed,
        allDay,
        recursionPattern: recurrencePattern,
        recursionCount: recurrenceCount.count,
        recurring,
        multiDay,
    }

    if (multiDay) {
        const startDate = formatDate(startDateTime)
        const endDate = formatDate(endDateTime)

        decodedEvent = {
            ...decodedEvent,
            startDate,
            endDate,
        }
    } else {
        const date = formatDate(startDateTime)

        decodedEvent = {
            ...decodedEvent,
            date,
        }
    }

    if (!allDay) {
        const startTime = formatTime(startDateTime)
        const endTime = formatTime(endDateTime)

        decodedEvent = {
            ...decodedEvent,
            startTime,
            endTime,
        }
    }

    return decodedEvent
}
