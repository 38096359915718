import React, { useState } from 'react'
import Form, { Field, FormFooter } from '@atlaskit/form'
import { LoadingButton } from '@atlaskit/button'
import TextField from '@atlaskit/textfield'
import SectionMessage from '@atlaskit/section-message'
import { useEventPortalService } from '../EventPortalService'
import { Padding } from './styledComponents'

const LoginForm = () => {
    const { login } = useEventPortalService()
    const [isLoading, setLoading] = useState(false)
    const initialLoginState = {
        loginAttempted: undefined,
        venueId: undefined,
        errorMessage: undefined,
    }
    const [loginState, setLoginState] = useState(initialLoginState)
    const { errorMessage, loginAttempted } = loginState

    const submitLoginForm = async (venueId) => {
        setLoginState(initialLoginState)
        setLoading(true)
        const loginResponse = await login(venueId)

        setLoginState((prevLoginState) => ({
            ...prevLoginState,
            loginAttempted: true,
            ...loginResponse,
        }))
        setLoading(false)
    }

    const LoginStatus = () => {
        if (errorMessage) {
            return (
                <SectionMessage appearance="error">
                    {errorMessage}
                </SectionMessage>
            )
        }

        return (
            <SectionMessage appearance="confirmation">
                <p>
                    An email has been sent to{' '}
                    <strong>
                        {loginState.venueName}, {loginState.venueCounty}
                    </strong>{' '}
                    with a login link
                </p>
            </SectionMessage>
        )
    }

    return (
        <div className="login">
            {loginAttempted && (
                <Padding>
                    <LoginStatus />
                </Padding>
            )}
            {!loginState.venueId && (
                <Form onSubmit={(data) => submitLoginForm(data.venueId)}>
                    {({ formProps }) => (
                        <form {...formProps}>
                            <Field
                                name="venueId"
                                defaultValue=""
                                label="House Number"
                                isRequired
                            >
                                {({ fieldProps }) => (
                                    <TextField {...fieldProps} />
                                )}
                            </Field>
                            <FormFooter>
                                <LoadingButton
                                    name="submit"
                                    type="submit"
                                    appearance="primary"
                                    isLoading={isLoading}
                                >
                                    Submit
                                </LoadingButton>
                            </FormFooter>
                        </form>
                    )}
                </Form>
            )}
        </div>
    )
}

export default LoginForm
