export const setError = (error, action) => {
    action({
        hasError: true,
        errorAppearance: 'danger',
        errorTitle: 'Error',
        errorMessage: error.response.data.errorMessage,
    })
}

export const handleError = (error, functionList) => {
    if (Object.keys(functionList).includes(error.response.status.toString())) {
        functionList[error.response.status]()
    } else {
        throw new Error(error.response.data.errorMessage)
    }
}
