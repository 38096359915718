import React from 'react'
import Feed from '../components/Feed'
import { RECURRING_ONLY } from '../consts'

const Approved = () => {
    return (
        <div className="approved">
            <Feed recursion={RECURRING_ONLY} />
        </div>
    )
}

export default Approved
