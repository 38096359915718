import React from 'react'
import PropTypes from 'prop-types'
import CalendarIcon from '@atlaskit/icon/glyph/calendar'
import ScheduleIcon from '@atlaskit/icon/glyph/schedule'
import BillingIcon from '@atlaskit/icon/glyph/billing'
import RetryIcon from '@atlaskit/icon/glyph/retry'
import RefreshIcon from '@atlaskit/icon/glyph/refresh'
import { EventTypeBadge, TypeLabel } from './styledComponents'

export default function EventTypeBadges({
    ticketed,
    allDay,
    multiDay,
    recurring,
    recursionCount,
}) {
    return (
        <div>
            {ticketed && (
                <EventTypeBadge>
                    <BillingIcon /> <TypeLabel>Ticketed</TypeLabel>
                </EventTypeBadge>
            )}
            {allDay && (
                <EventTypeBadge>
                    <CalendarIcon /> <TypeLabel>All Day</TypeLabel>
                </EventTypeBadge>
            )}
            {multiDay && (
                <EventTypeBadge>
                    <ScheduleIcon /> <TypeLabel>Multiday</TypeLabel>
                </EventTypeBadge>
            )}
            {recurring && (
                <EventTypeBadge>
                    <RetryIcon /> <TypeLabel>Recurring</TypeLabel>
                </EventTypeBadge>
            )}
            {recursionCount === 0 && recurring && (
                <EventTypeBadge>
                    <RefreshIcon /> <TypeLabel>Recurrs Forever</TypeLabel>
                </EventTypeBadge>
            )}
        </div>
    )
}
EventTypeBadges.propTypes = {
    allDay: PropTypes.bool,
    ticketed: PropTypes.bool,
    multiDay: PropTypes.bool,
    recurring: PropTypes.bool,
    recursionCount: PropTypes.number,
}
