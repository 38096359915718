import React, { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import { Field, ErrorMessage, Fieldset } from '@atlaskit/form'
import TextArea from '@atlaskit/textarea'
import TextField from '@atlaskit/textfield'
import { Checkbox } from '@atlaskit/checkbox'
import Select from '@atlaskit/select'
import {
    Legend,
    InlinePadding,
    Padding,
    CheckboxGrid,
} from './styledComponents'
import Loader from './Loader'
import InternalCategory from './InternalCategory'
import useCategoryState from '../stores/useCategoryState'

export default function StandardEventFields({
    name = '',
    category = null,
    description = '',
    categories,
    internalCategories,
    selectedInternalCategories,
}) {
    const validateSelect = (value) => (!value ? 'EMPTY' : undefined)
    const [selectedCategories, setSelectedCategories] = useCategoryState()
    const defaultSelect = () => {
        return category ? { label: category, value: category } : null
    }

    const categoryCheckboxHandler = (event) => {
        if (!event.target.checked) {
            setSelectedCategories((prevSelected) =>
                prevSelected.filter((cat) => cat !== event.target.value)
            )

            return
        }

        setSelectedCategories((prevSelected) => [
            ...prevSelected,
            event.target.value,
        ])
    }

    useEffect(() => {
        if (
            internalCategories &&
            selectedInternalCategories &&
            selectedInternalCategories.length
        ) {
            setSelectedCategories(selectedInternalCategories)
        }
    }, [internalCategories, selectedInternalCategories, setSelectedCategories])

    return (
        <div>
            <Field
                name="name"
                defaultValue={name}
                label="Event Name"
                isRequired
            >
                {({ fieldProps }) => <TextField {...fieldProps} />}
            </Field>
            {categories ? (
                <Field
                    name="category"
                    label="Category"
                    defaultValue={defaultSelect()}
                    validate={validateSelect}
                >
                    {({ fieldProps, error }) => (
                        <Fragment>
                            <Select
                                {...fieldProps}
                                options={categories}
                                isClearable
                            />
                            {error === 'EMPTY' && (
                                <ErrorMessage>
                                    You must select a category
                                </ErrorMessage>
                            )}
                        </Fragment>
                    )}
                </Field>
            ) : (
                <Loader size="small" />
            )}

            {internalCategories ? (
                <Padding>
                    <Legend>Reporting Category</Legend>
                    <CheckboxGrid>
                        {internalCategories.map((cat, index) => {
                            return (
                                <Checkbox
                                    key={`${cat.label.trim()}-${index}`}
                                    label={cat.label}
                                    value={cat.value}
                                    onChange={categoryCheckboxHandler}
                                    isChecked={selectedCategories.some(
                                        (selCat) => selCat === cat.value
                                    )}
                                />
                            )
                        })}
                    </CheckboxGrid>
                </Padding>
            ) : (
                <Loader size="small" />
            )}
            <InternalCategory />
            <Fieldset legend="Event Description" isRequired>
                <InlinePadding>
                    <p>
                        <small>
                            Describe your event and include reasons why guests
                            shouldn’t miss it. If your event is ticketed{' '}
                            <strong>
                                please include ticket price details.{' '}
                            </strong>
                            You can use these{' '}
                            <a
                                href="https://www.greeneking-pubs.co.uk/media/28181/events-hub-suggested-messaging.pdf"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                description templates
                            </a>{' '}
                            for inspiration
                        </small>
                    </p>
                </InlinePadding>
                <Field
                    name="description"
                    defaultValue={description}
                    label="Description"
                    isRequired
                >
                    {({ fieldProps }) => <TextArea {...fieldProps} rows="4" />}
                </Field>
            </Fieldset>
        </div>
    )
}
StandardEventFields.schema = {
    name: yup.string().required('The event name is required'),
    category: yup
        .object()
        .required('You must select a category')
        .shape({ label: yup.string(), value: yup.string() })
        .nullable(),
    description: yup.string('You must enter a description').required(),
}

const categoryOptions = PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
})

StandardEventFields.propTypes = {
    name: PropTypes.string,
    category: PropTypes.string,
    description: PropTypes.string,
    categories: PropTypes.arrayOf(categoryOptions),
    internalCategories: PropTypes.arrayOf(categoryOptions),
    selectedInternalCategories: PropTypes.array,
}
