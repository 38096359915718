/* eslint-disable import/prefer-default-export */
export const LONG_LEASE_EXPIRY = 55
export const APPROVER = 'approver'
export const PUB_MANAGER = 'pubManager'
export const APPROVER_PER_PAGE = 25
export const MANAGER_PER_PAGE = 10
export const CATEGORIES = [
    { label: 'Sport & Games', value: 'Sport & Games' },
    { label: 'Entertainment', value: 'Entertainment' },
    { label: 'Theme Nights', value: 'Theme Nights' },
    { label: 'Food & Drink', value: 'Food & Drink' },
    { label: 'Competitions', value: 'Competitions' },
    { label: 'The best of the rest', value: 'The best of the rest' },
]

export const STATUS_CODES = [
    { label: 'Approved', value: 'success', workflowStatus: 0 },
    { label: 'Rejected', value: 'removed', workflowStatus: 1 },
    { label: 'Deleted', value: 'new', workflowStatus: 2 },
    { label: 'Pending Approval', value: 'inprogress', workflowStatus: 3 },
    { label: 'Edited', value: 'moved', workflowStatus: 4 },
]

export const NO_RECURSION = 'None'

export const RECURRANCE_CODES = [
    { label: 'Daily', status: 1 },
    { label: 'Weekly', status: 2 },
    { label: 'Fortnightly', status: 3 },
]

export const RECURSION_OPTIONS = [
    {
        name: 'recursionPattern',
        value: 'Daily',
        label: 'Daily',
    },
    {
        name: 'recursionPattern',
        value: 'Weekly',
        label: 'Weekly',
    },
    {
        name: 'recursionPattern',
        value: 'Fortnightly',
        label: 'Fortnightly',
    },
]

export const REJECTION_REASONS = [
    {
        name: 'rejectionReason',
        value: 'cancelled',
        label: 'Cancelled event',
    },
    {
        name: 'rejectionReason',
        value: 'spelling',
        label: 'Contains spelling or grammatical errors',
    },
    {
        name: 'rejectionReason',
        value: 'copyright',
        label: 'Copyright or trademark infringement',
    },
    {
        name: 'rejectionReason',
        value: 'offensive',
        label: 'Offensive or illegal content',
    },
    {
        name: 'rejectionReason',
        value: 'invalidOffer',
        label: 'Contains invalid promotions or offers',
    },
    {
        name: 'rejectionReason',
        value: 'notEvent',
        label: 'This is not an actual event',
    },
]
export const INITIAL_FORM_SHAPE = {
    date: undefined,
    startTime: undefined,
    endTime: undefined,
    name: undefined,
    description: undefined,
    internalCategories: undefined,
    category: undefined,
    ticketed: undefined,
    recursionPattern: undefined,
    recursionCount: undefined,
}

export const INTERACTION_DELAY = 1000

export const LOGIN_TOKEN = 'token'

export const LOGGED_IN_COOKIE = 'PortalAuth'

export const LOGIN_TIMEOUT =
    'Sorry your session has timed, out please login again.'

export const RECURRING_ONLY = 'OnlyReoccurring'
export const NOT_RECURRING = 'OnlyNonReoccurring'

export const SORT_BY = [
    {
        label: 'Created',
        value: 'createdAtUtc',
    },
    {
        label: 'Event Date',
        value: 'startAtUtc',
    },
]

export const VALIDATION_ERRORS = {
    startDate: {
        approver: 'Enter an event date of today, or in the future',
        pubManager: 'Start Date must be in the future',
    },
}
