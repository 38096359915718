import React from 'react'
import Feed from '../components/Feed'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'

const Pending = () => {
    return (
        <div className="pending">
            <Feed
                filterStatus={[
                    getWorkFlowStatus('Pending Approval'),
                    getWorkFlowStatus('Edited'),
                ]}
            />
        </div>
    )
}

export default Pending
