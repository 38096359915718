import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import Page, { Grid, GridColumn } from '@atlaskit/page'
import { ButtonGroup } from '@atlaskit/button'
import PageHeader from '@atlaskit/page-header'
import SearchIcon from '@atlaskit/icon/glyph/search'
import { useAuth } from './authContext'
import ButtonLink from './components/ButtonLink'
import { SubTitle } from './components/styledComponents'
import { APPROVER } from './consts'
import useErrorState from './stores/useErrorState'
import ErrorModal from './components/ErrorModal'
import routes from './routes'
import Logout from './components/Logout'

export default function LoggedInApp() {
    const { userRole } = useAuth()
    const [{ hasError }] = useErrorState()

    const actions = () => (
        <ButtonGroup>
            <ButtonLink href="/" label="All Events" />
            {userRole === APPROVER && (
                <ButtonLink href="/reporting" label="Event Reports" />
            )}
            {userRole !== APPROVER && (
                <Fragment>
                    <ButtonLink href="/pending" label="Pending" />
                    <ButtonLink href="/approved" label="Approved" />
                    <ButtonLink href="/rejected" label="Rejected" />
                    <ButtonLink href="/recurring" label="Recurring" />
                </Fragment>
            )}

            <ButtonLink
                href="/search"
                icon={<SearchIcon label="Search" size="small" />}
                label="Search"
            />
            {userRole !== APPROVER && (
                <ButtonLink
                    href="/addevent"
                    appearance="primary"
                    label="Add Event"
                />
            )}
        </ButtonGroup>
    )

    return (
        <div className="App">
            <Page>
                {hasError && <ErrorModal />}
                <Grid>
                    <GridColumn>
                        <PageHeader actions={actions()} bottomBar={<Logout />}>
                            Events Portal
                        </PageHeader>
                        <SubTitle>
                            <Switch>
                                {routes.map((route, index) => (
                                    <Route
                                        key={index}
                                        path={route.path}
                                        exact={route.exact}
                                        // eslint-disable-next-line react/no-children-prop
                                        children={<route.pageTitle />}
                                    />
                                ))}
                            </Switch>
                        </SubTitle>
                    </GridColumn>
                </Grid>
                <Grid>
                    <GridColumn>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route
                                    key={index}
                                    path={route.path}
                                    exact={route.exact}
                                    // eslint-disable-next-line react/no-children-prop
                                    children={<route.main />}
                                />
                            ))}
                        </Switch>
                    </GridColumn>
                </Grid>
            </Page>
        </div>
    )
}
