import React from 'react'
import SignOutIcon from '@atlaskit/icon/glyph/sign-out'
import Button from '@atlaskit/button'
import { useAuth } from '../authContext'
import useErrorState from '../stores/useErrorState'
import { TextRight } from './styledComponents'

export default function Logout() {
    const { logUserOut } = useAuth()
    const [, setErrorState] = useErrorState()

    const triggerLogOutModal = () => {
        setErrorState({
            hasError: true,
            errorTitle: 'Warning',
            errorAppearance: 'warning',
            errorMessage: 'Are you sure you want to log out?',
            errorActions: [
                { text: 'Logout', onClick: logUserOut, appearance: 'warning' },
            ],
        })
    }

    return (
        <TextRight>
            <Button
                iconAfter={<SignOutIcon label="Logout icon" size="small" />}
                appearance="subtle"
                onClick={triggerLogOutModal}
            >
                Logout
            </Button>
        </TextRight>
    )
}
