import React from 'react'
import Feed from '../components/Feed'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'

const Rejected = () => {
    return (
        <div className="rejected">
            <Feed filterStatus={getWorkFlowStatus('Rejected')} />
        </div>
    )
}

export default Rejected
