import React, { useState } from 'react'
import Toggle from '@atlaskit/toggle'
import { Field } from '@atlaskit/form'
import TextField from '@atlaskit/textfield'
import { ToggleWrapper } from './styledComponents'

export default function InternalCategory() {
    const [show, setShow] = useState(false)

    const toggleShow = () => {
        setShow((wasShown) => !wasShown)
    }

    return (
        <div>
            <ToggleWrapper>
                Add New Reporting Category
                <Toggle
                    label="Add Reporting Category"
                    size="large"
                    name="addInternal"
                    isDefaultChecked={show}
                    onChange={toggleShow}
                    testId="internalToggle"
                />
            </ToggleWrapper>

            {show && (
                <Field name="internalCategory" label="Reporting Category">
                    {({ fieldProps }) => <TextField {...fieldProps} />}
                </Field>
            )}
        </div>
    )
}
