import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Redirect, useHistory } from 'react-router-dom'
import * as yup from 'yup'
import shallowequal from 'shallowequal'
import { Grid, GridColumn } from '@atlaskit/page'
import Form, { FormFooter, CheckboxField } from '@atlaskit/form'
import Button, { LoadingButton } from '@atlaskit/button'
import { Checkbox } from '@atlaskit/checkbox'
import decodeEvent from '../tools/decodeEvent'
import encodeEvent from '../tools/encodeEvent'
import processErrors from '../tools/processErrors'
import { useEventPortalService } from '../EventPortalService'
import useErrorState from '../stores/useErrorState'
import useCategoryState from '../stores/useCategoryState'
import { useAuth } from '../authContext'
import makeOptions from '../tools/makeOptions'
import FormIntroText from './FormIntroText'
import StandardEventFields from './StandardEventFields'
import SingleDatePicker from './SingleDatePicker'
import MultiDayDatePickers from './MultiDayDatePickers'
import RecurringEvents from './RecurringEvents'
import TimePickers from './TimePickers'
import Loader from './Loader'
import { INITIAL_FORM_SHAPE } from '../consts'
import { Padding, NarrowContainer, MarginRightAuto } from './styledComponents'
import { setError, handleError } from '../tools/errorHandlers'
import isEventRecurring from '../tools/isEventRecurring'

const validationOptions = {
    abortEarly: false,
}

export default function EditEventForm({ id }) {
    const [isLoading, setLoading] = useState(false)
    const { getEvent, updateEvent, getCategories } = useEventPortalService()
    const [, setErrorState] = useErrorState()
    const { loginTimeout, userRole } = useAuth()
    const [isRecurring, setRecurring] = useState(false)
    const [categoryState, setCategoryState] = useState({
        categories: undefined,
        internalCategories: undefined,
    })
    const [selectedCategories, setSelectedCategories] = useCategoryState()
    const { categories, internalCategories } = categoryState
    const [initialFormState, setInitialFormState] = useState(INITIAL_FORM_SHAPE)
    const [isSuccess, setSuccess] = useState(false)
    const [eventTypeState, setEventTypeState] = useState({
        allDay: false,
        multiDay: false,
    })

    // TODO: this is a temporary fix as selected categories are pulling
    // through from previously viewed events and not clearing on load
    useEffect(() => {
        setSelectedCategories([])
        // eslint-disable-next-line
    }, [])

    /*
        https://reacttraining.com/blog/react-router-v5-1/#usehistory
        version 6 upgrade issue
    */
    const history = useHistory()

    useEffect(() => {
        const getEventData = async (eventId) => {
            setLoading(true)
            const eventData = await getEvent(eventId)

            const event = decodeEvent(eventData)

            setEventTypeState({
                allDay: event.allDay,
                multiDay: event.multiDay,
            })
            if (isEventRecurring(event.recursionPattern)) {
                setRecurring(true)
            }

            setInitialFormState(event)
            setLoading(false)
        }

        getEventData(id)
    }, [getEvent, id])

    const toggleEventType = (event) => {
        const { name } = event.target

        setEventTypeState((prevEventTypeState) => ({
            ...prevEventTypeState,
            [name]: !prevEventTypeState[name],
        }))
    }

    const eventSchema = useCallback(() => {
        const { allDay, multiDay } = eventTypeState

        const schema = () => {
            /* eslint-disable operator-linebreak  */
            return {
                ...StandardEventFields.schema,
                ...(multiDay
                    ? MultiDayDatePickers.schema
                    : SingleDatePicker.schema),
                ...(multiDay && TimePickers.multiDaySchema),
                ...(!allDay && !multiDay && TimePickers.schema),
                ...(isRecurring && RecurringEvents.schema),
            }
            /* eslint-enable operator-linebreak */
        }

        return yup.object(schema())
    }, [eventTypeState, isRecurring])

    const handleSubmit = async (data) => {
        const { allDay, multiDay } = eventTypeState
        const schema = eventSchema()

        const validation = await schema
            .validate(data, {
                ...validationOptions,
                context: { role: userRole },
            })
            .catch((err) => {
                return processErrors(err, data)
            })

        if (!shallowequal(validation, data)) {
            return validation
        }

        setLoading(true)

        const encodedEvent = encodeEvent(
            data,
            allDay,
            multiDay,
            isRecurring,
            selectedCategories
        )

        const submitEvent = {
            ...encodedEvent,
            id,
        }

        try {
            const submissionResponse = await updateEvent(submitEvent)

            if (submissionResponse) {
                setSuccess(true)
            }
        } catch (error) {
            handleError(error, {
                400: () => setError(error, setErrorState),
                401: () => loginTimeout(),
            })
        }

        return true
    }

    useEffect(() => {
        const getCats = async () => {
            const cats = await getCategories()

            if (cats && Array.isArray(cats)) {
                setCategoryState({
                    categories: makeOptions(
                        cats.filter((cat) => !cat.internal)
                    ),
                    internalCategories: makeOptions(
                        cats.filter((cat) => cat.internal)
                    ),
                })
            }
        }

        if (!categories && !internalCategories) {
            getCats()
        }
    }, [categories, getCategories, setCategoryState, internalCategories])

    if (isSuccess) {
        return <Redirect to="/success" />
    }

    if (isLoading && initialFormState) {
        return <Loader />
    }

    return (
        <Form onSubmit={(data) => handleSubmit(data)}>
            {({ formProps, getValues }) => (
                <form {...formProps} name="text-fields">
                    <NarrowContainer data-testid="edit-form">
                        <h2>Edit Event</h2>
                        <FormIntroText />
                        <StandardEventFields
                            category={initialFormState.category}
                            name={initialFormState.name}
                            description={initialFormState.description}
                            {...categoryState}
                            selectedInternalCategories={
                                initialFormState.internalCategories
                            }
                        />
                        <Grid>
                            <GridColumn>
                                <Padding>
                                    <Checkbox
                                        name="allDay"
                                        value="allDay"
                                        label="All Day Event"
                                        isDisabled={
                                            eventTypeState.multiDay ||
                                            isRecurring
                                        }
                                        isChecked={eventTypeState.allDay}
                                        onChange={toggleEventType}
                                    />
                                </Padding>
                            </GridColumn>
                            <GridColumn>
                                <Padding>
                                    <Checkbox
                                        label="Multi-day Event"
                                        name="multiDay"
                                        value="multiDay"
                                        isDisabled={
                                            eventTypeState.allDay || isRecurring
                                        }
                                        isChecked={eventTypeState.multiDay}
                                        onChange={toggleEventType}
                                    />
                                </Padding>
                            </GridColumn>
                        </Grid>
                        {eventTypeState.multiDay ? (
                            <MultiDayDatePickers
                                startDate={initialFormState.startDate}
                                endDate={initialFormState.endDate}
                            />
                        ) : (
                            <SingleDatePicker date={initialFormState.date} />
                        )}
                        {!eventTypeState.allDay && (
                            <TimePickers
                                startTime={initialFormState.startTime}
                                endTime={initialFormState.endTime}
                            />
                        )}
                        <CheckboxField
                            name="ticketed"
                            value="ticketed"
                            defaultIsChecked={initialFormState.ticketed}
                        >
                            {({ fieldProps }) => (
                                <Checkbox
                                    {...fieldProps}
                                    label="Ticketed Event"
                                />
                            )}
                        </CheckboxField>
                    </NarrowContainer>
                    <RecurringEvents
                        isRecurring={isRecurring}
                        setRecurring={setRecurring}
                        eventTypeState={eventTypeState}
                        recursionCount={initialFormState.recursionCount}
                        recursionPattern={initialFormState.recursionPattern}
                        formState={getValues}
                    />
                    <NarrowContainer>
                        <FormFooter>
                            <MarginRightAuto>
                                <Button
                                    type="button"
                                    appearance="default"
                                    onClick={() => history.goBack()}
                                >
                                    Cancel
                                </Button>
                            </MarginRightAuto>
                            <LoadingButton
                                type="submit"
                                appearance="primary"
                                isLoading={isLoading}
                            >
                                Submit
                            </LoadingButton>
                        </FormFooter>
                    </NarrowContainer>
                </form>
            )}
        </Form>
    )
}
EditEventForm.propTypes = {
    id: PropTypes.string.isRequired,
}
