import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Lozenge from '@atlaskit/lozenge'
import { STATUS_CODES } from '../consts'
import getWorkFlowStatus from '../tools/getWorkFlowStatus'

export default function StatusLozenge({ status }) {
    const selectLozenge = (statusNumber) => {
        return STATUS_CODES.filter((code) => {
            return code.workflowStatus === statusNumber
        })
    }
    const statusCodes = useMemo(() => {
        const lozenges = selectLozenge(status)

        if (status === getWorkFlowStatus('Edited')) {
            const pending = selectLozenge(getWorkFlowStatus('Pending Approval'))

            return [...lozenges, ...pending]
        }

        return lozenges
    }, [status])

    return (
        <div className="status-lozenges">
            {statusCodes.map((statusCode) => (
                <Lozenge key={statusCode.value} appearance={statusCode.value}>
                    {statusCode.label}
                </Lozenge>
            ))}
        </div>
    )
}

StatusLozenge.propTypes = {
    status: PropTypes.number.isRequired,
}
