// TODO: This file is copy/pasted from GKWF and should be imported from a shared repository
import { useCallback, useEffect, useState, useMemo } from 'react'
import shallowEqual from 'shallowequal'

class Store {
    subscriptions = []

    constructor(initialState) {
        this.state = initialState
    }

    setState(updater) {
        this.state =
            typeof updater === 'function' ? updater(this.state) : updater
        this.subscriptions.forEach((subscription) => subscription(this.state))
    }

    getState() {
        return this.state
    }

    subscribe(subscription) {
        this.subscriptions.push(subscription)

        return () => {
            this.subscriptions = this.subscriptions.filter(
                (item) => item !== subscription
            )
        }
    }
}

export default function createStore({ initialState }) {
    const store = new Store(initialState)

    return (selector) => {
        const storeUpdater = useCallback(
            (updater) => store.setState(updater),
            []
        )
        const calculateState = useCallback(
            (newValue) => (selector ? selector(newValue) : newValue),
            [selector]
        )
        const [localState, setLocalState] = useState(
            calculateState(store.getState())
        )

        useEffect(
            () =>
                store.subscribe((newValue) => {
                    const selectedValue = calculateState(newValue)

                    if (!shallowEqual(localState, selectedValue)) {
                        setLocalState(selectedValue)
                    }
                }),
            [calculateState, localState]
        )

        return useMemo(
            () => [localState, storeUpdater],
            [localState, storeUpdater]
        )
    }
}
