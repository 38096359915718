import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import * as yup from 'yup'
import {
    parseISO,
    formatISO,
    add,
    isAfter,
    isBefore,
    startOfYesterday,
} from 'date-fns'
import { Grid, GridColumn } from '@atlaskit/page'
import { Field, ErrorMessage } from '@atlaskit/form'
/*  
    TODO: check when update of depenedency is available
    https://reactjs.org/blog/2018/03/27/update-on-async-rendering.html#gradual-migration-path
    causing:
    Warning: componentWillReceiveProps has been renamed, and is not recommended for use. 
    See https://fb.me/react-unsafe-component-lifecycles for details.

    Please update the following components: DatePicker, TimePicker
*/
import { DatePicker } from '@atlaskit/datetime-picker'
import { PUB_MANAGER, VALIDATION_ERRORS } from '../consts'
import useSettings from '../shared/hooks/useSettings'

export default function MultiDayDatePickers({ startDate = '', endDate = '' }) {
    const { locale } = useSettings()
    const today = formatISO(Date.now(), { representation: 'date' })

    return (
        <Grid>
            <GridColumn>
                <div>
                    <Field
                        name="startDate"
                        label="Start Date"
                        defaultValue={startDate || today}
                        value={startDate || null}
                        isRequired
                    >
                        {({ fieldProps, error, meta }) => (
                            <Fragment>
                                <DatePicker
                                    {...fieldProps}
                                    locale={locale}
                                    testId="start-date-picker"
                                />
                                {error && !meta.dirtySinceLastSubmit && (
                                    <ErrorMessage>{error}</ErrorMessage>
                                )}
                            </Fragment>
                        )}
                    </Field>
                </div>
            </GridColumn>
            <GridColumn>
                <div>
                    <Field
                        name="endDate"
                        label="End Date"
                        defaultValue={endDate || today}
                        value={endDate || null}
                        isRequired
                    >
                        {({ fieldProps, error }) => (
                            <Fragment>
                                <DatePicker
                                    {...fieldProps}
                                    locale={locale}
                                    testId="end-date-picker"
                                />
                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Fragment>
                        )}
                    </Field>
                </div>
            </GridColumn>
        </Grid>
    )
}

MultiDayDatePickers.schema = {
    startDate: yup
        .string()
        .required()
        .when('$role', {
            is: PUB_MANAGER,
            then: yup
                .string()
                .test(
                    'is-in-future',
                    VALIDATION_ERRORS.startDate.pubManager,
                    (value) => {
                        const today = Date.now()

                        return isAfter(parseISO(value), today)
                    }
                ),
            otherwise: yup
                .string()
                .test(
                    'is-today-or-future',
                    VALIDATION_ERRORS.startDate.approver,
                    (value) => {
                        const yesterday = startOfYesterday()

                        return isAfter(parseISO(value), yesterday)
                    }
                ),
        })
        .test(
            'is-within-1-year',
            'Event Date must be within the next year',
            (value) => {
                const inOneYear = add(Date.now(), { years: 1 })

                return isBefore(parseISO(value), inOneYear)
            }
        ),
    endDate: yup
        .string()
        .required()
        .test(
            'is-after',
            'End Date must be after Start Date',
            // eslint-disable-next-line func-names
            function (value) {
                const { startDate } = this.parent

                return isAfter(parseISO(value), parseISO(startDate))
            }
        ),
}
MultiDayDatePickers.propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
}
