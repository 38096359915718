import React from 'react'
import Homepage from './pages/Homepage'
import Approved from './pages/Approved'
import Rejected from './pages/Rejected'
import Pending from './pages/Pending'
import EditEvent from './pages/EditEvent'
import Success from './pages/Success'
import Search from './pages/Search'
import AddEventForm from './components/AddEventForm'
import Recurring from './pages/Recurring'
import Reporting from './pages/Reporting'

/* FIXME: refactor react-router to pass linting */
/* eslint-disable react/display-name */

export default [
    {
        path: '/',
        exact: true,
        pageTitle: () => <h2>All Events</h2>,
        main: () => <Homepage />,
    },
    {
        path: '/approved',
        pageTitle: () => <h2>Approved Events</h2>,
        main: () => <Approved />,
    },
    {
        path: '/pending',
        pageTitle: () => <h2>Pending Events</h2>,
        main: () => <Pending />,
    },
    {
        path: '/rejected',
        pageTitle: () => <h2>Rejected Events</h2>,
        main: () => <Rejected />,
    },
    {
        path: '/recurring',
        pageTitle: () => <h2>Recurring Events</h2>,
        main: () => <Recurring />,
    },
    {
        path: '/addevent',
        pageTitle: () => <h2>Add Event</h2>,
        main: () => <AddEventForm />,
    },
    {
        path: '/editevent',
        pageTitle: () => <h2>Edit Event</h2>,
        main: () => <EditEvent />,
    },
    {
        path: '/success',
        pageTitle: () => <h2>Success</h2>,
        main: () => <Success />,
    },
    {
        path: '/search',
        pageTitle: () => <h2>Search</h2>,
        main: () => <Search />,
    },
    {
        path: '/reporting',
        pageTitle: () => <h2>Reporting</h2>,
        main: () => <Reporting />,
    },
]
