export default function processErrors(yupValidation, data) {
    const errors = { ...data }

    // eslint-disable-next-line func-names
    Object.keys(errors).forEach(function (index) {
        errors[index] = undefined
    })
    yupValidation.inner.forEach((error) => {
        // we overwrite the undefined values with the error message
        errors[error.path] = error.message
    })
    return errors
}
