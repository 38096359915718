import React from 'react'
import ReportingForm from '../components/ReportingForm'

const Reporting = () => {
    return (
        <div className="reporting">
            <ReportingForm />
        </div>
    )
}

export default Reporting
