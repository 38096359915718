import createStore from '../shared/hooks/createStore'

export const initialState = {
    count: 0,
    results: null,
}

export default createStore({
    initialState,
})
